import React from "react"
import { Link, graphql } from "gatsby"
import Layout from '../../components/Layout';
import SearchComponent from "../../components/SearchComponent";

const Home = ({
  data: {
    allFile: { edges },
  },
}) => {
  const Notes = edges.map(edge => {
    if (edge.node.childMdx && edge.node.childMdx.slug && !(("" + edge.node.childMdx.slug).includes(".trash"))) {
      return (
        <article>
          <Link to={`/notes/${edge.node.childMdx.slug}`}>
            <h1>{edge.node.name}</h1>
          </Link>
          <p>Time to Read : {edge.node.childMdx.timeToRead} mins</p>
          <p>Word Count : {edge.node.childMdx.wordCount.words}</p>
        </article>
      )
    }
    else return "";
  });

  return (<Layout><section>{SearchComponent}{Notes}</section></Layout>)
}

export default Home

//There is some issue with using All Files. If we use that we can add created and updated time too
export const pageQuery = graphql`
query AllNotesWithReferencesUsingFileSortByRecency {
  allFile(sort: {fields: birthTime, order: DESC}) {
    edges {
      node {
        childMdx {
          slug
          timeToRead
          wordCount {
            words
          }
          inboundReferences {
            ... on Mdx {
              id
              slug
            }
          }
          excerpt(pruneLength: 100)
        }
        birthTime
        name
        size
        prettySize
      }
    }
  }
}
`